
.ellipses {
  white-space: nowrap; /* 禁止换行 */
  overflow: hidden; /* 超出容器部分隐藏 */
  text-overflow: ellipsis; /* 超出部分用省略号代替 */
}

.ellipses-2 {
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2; /* 限制为2行 */
  -webkit-box-orient: vertical; /* 必须设置的属性 */
  overflow: hidden; /* 隐藏超出的部分 */
  text-overflow: ellipsis; /* 超出部分显示省略号 */
}

.content-analysis {
  padding: 24px;
  background-color: #fff;

  .header-box {
    /* element ui style */
    ::v-deep .el-menu-item {
      color: rgba(37, 38, 50, 0.6);
      font-family: PingFang SC, PingFang SC;
      font-size: 18px;
      padding: 0 4px;
      margin-right: 24px;
      height: 50px;
      line-height: 50px;
      // line-height: 21px;
      font-weight: 400;
    }

    ::v-deep .el-menu-item.is-active {
      color: #252632;
    }

    ::v-deep .el-menu--horizontal > .el-menu-item.is-active {
      border-bottom-color: #fe2c55;
    }
  }

  .content-card-box {
    margin-block: 20px;
    .card {
      background-color: #f7f9fb;
      height: 220px;
      width: 100%;
      padding: 20px;
      border-radius: 8px;
      box-sizing: border-box;
      display: flex;
      gap: 16px;
      position: relative;

      &_cover-img {
        position: relative;
        cursor: pointer;
        flex-grow: 0;

        img {
          width: 135px;
          height: 180px;
          border-radius: 8px;
          object-fit: cover;
        }

        .img-play-icon {
          color: rgba(255, 255, 255, 0.6);
          font-size: 80px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &_info {
        width: 70%;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #777e87;
        text-align: left;
        font-style: normal;
        text-transform: none;

        & > div {
          margin-bottom: 24px;
        }

        .info-title {
          font-weight: 400;
          font-size: 16px;
          color: #161823;
        }
      }

      &_more {
        position: absolute;
        right: 20px;
        top: 20px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #594fee;
        text-align: left;
        font-style: normal;
        cursor: pointer;

        .select-icon {
          margin-right: 5px;
        }
      }
    }
  }

  .drawer-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: rgba(37, 38, 50, 0.6);
    font-family: PingFang SC, PingFang SC;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;

    &_label {
      font-size: 18px;
      color: #252632;
    }
  }

  .drawer-content {
    &-item {
      display: flex;
      gap: 8px;
      margin-bottom: 24px;
      cursor: pointer;

      .item-img {
        width: 67px;
        height: 90px;
        border-radius: 2px;
        object-fit: cover;
      }

      .item-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & > div {
          flex-grow: 0;
        }

        width: 80%;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #777e87;
        text-align: left;
        font-style: normal;
        text-transform: none;

        &_title {
          font-size: 16px;
          color: #252632;

          display: -webkit-box;
          line-clamp: 2;
          -webkit-line-clamp: 2; /* 限制为2行 */
          -webkit-box-orient: vertical; /* 必须设置的属性 */
          overflow: hidden; /* 隐藏超出的部分 */
          text-overflow: ellipsis; /* 超出部分显示省略号 */
        }

        &_empty {
          flex-grow: 1;
        }

        &_account {
          display: flex;
          align-items: center;
          gap: 4px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: rgba(51, 51, 51, 0.6);
          line-height: 14px;
          text-align: left;

          img {
            width: 16px;
            height: 16px;
            border-radius: 50%;
          }
        }
      }
    }

    .medio-load {
      display: flex;
      justify-content: center;
      padding: 10px 0 0 0;

      span {
        font-weight: 400;
        font-size: 16px;
        color: #888888;
        line-height: 19px;
        cursor: pointer;
      }
    }
  }

  ::v-deep .el-drawer__body::-webkit-scrollbar {
    width: 10px;
    /* 滚动条的宽度 */
  }

  ::v-deep .el-drawer__body::-webkit-scrollbar-thumb {
    background-color: rgba(37, 38, 50, 0.2);
    /* 滚动条的颜色 */
    border-radius: 20px;
    /* 滚动条的圆角半径 */
  }

  ::v-deep .el-drawer__body::-webkit-scrollbar-thumb:hover {
    background-color: rgba(37, 38, 50, 0.2);
    /* 滚动条的悬停颜色 */
  }

  ::v-deep .el-drawer__body::-webkit-scrollbar-track {
    background-color: #fff;
    /* 滚动条的轨道颜色 */
    border-radius: 20px;
    /* 滚动条轨道的圆角半径 */
  }

  ::v-deep .el-drawer__header,
  ::v-deep .el-drawer__body {
    padding: 24px;
    margin: 0;
  }
}
